// components/Nav.js

'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import NavLink from './NavLink';
import { getSessionUser } from '@/utils/auth';
import { getSession } from 'next-auth/react';
import { USER_ROLES } from '@/utils/constants';

export default function Nav({ user }) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <nav className="bg-primary-gray py-4 top-0 w-full z-10 text-white shadow-md md:space-y-4">
            {/* Primary Navigation Container */}
            <div className="lg:container mx-auto flex justify-between items-center relative px-4">
                {/* Buy Now Button (Visible on Desktop) */}
                <div className="hidden md:flex flex-1 items-center">
                </div>

                {/* Logo and Brand Name (Centered) */}
                <Link href={'/'} className="">
                    <div className="flex items-center cursor-pointer justify-center ml-2">
                        <Image
                            alt="6ix Shooter Logo"
                            src="/6ixshooter.png"
                            className="transition md:hidden mr-4 h-fit duration-300 ease-in-out rounded-md transform hover:scale-110"
                            width={50}
                            height={50}
                            priority

                        />
                        <Image
                            alt="6ix Shooter Logo"
                            src="/6ixShooterAcademy.png"
                            className="transition duration-300 mr-4 md:mr-0 h-fit min-w-48 ease-in-out w-auto rounded-md transform hover:scale-110"
                            width={350}
                            height={70}
                            priority
                        />
                    </div>
                </Link>

                {/* Navigation Items (Right Side) */}
                <div className="flex-1 flex justify-end items-center">
                    {/* Hamburger Menu Button (Visible on Mobile) */}
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            className="p-2 focus:outline-none"
                            aria-label={isMobileMenuOpen ? 'Close Menu' : 'Open Menu'}
                        >
                            <svg
                                className="w-6 h-6 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                {isMobileMenuOpen ? (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        className="w-6 h-6" // Adjust size as needed
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6h16 M4 12h16 M4 18h16"
                                        />
                                    </svg>


                                )}
                            </svg>
                        </button>
                    </div>

                    {/* Desktop Navigation (Visible on Desktop) */}
                    <div className="hidden md:flex items-center space-x-6">
                        {user ? (
                            <div className={`flex gap-6 ${user.role === USER_ROLES.ADMIN ? '' : ''}`}>
                                <Link
                                    className="text-xl active:scale-95 p-2 md:px-3 md:py-2 bg-6ixshooter-blue text-white font-bold rounded-md 

                                    shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-blue-hover focus:outline-none 
                                    tracking-wide"
                                    href={'/profile'}
                                >
                                    Profile
                                </Link>
                                {user.role === USER_ROLES.ADMIN && (
                                    <Link
                                        className="text-xl p-2 md:px-3 md:py-2 bg-6ixshooter-red text-white font-bold rounded-md 
                                        active:scale-95
                                        shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-red-hover focus:outline-none 
                                        tracking-wide"
                                        href={'/admin'}
                                    >
                                        Admin Page
                                    </Link>
                                )}
                            </div>
                        ) : (
                            <div className="flex gap-6">
                                <Link
                                    href={'/auth/login'}
                                        className="text-xl p-2 md:px-3 md:py-2 w-fit bg-6ixshooter-blue text-white font-bold rounded-md 
                                    shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-blue-hover focus:outline-none 
                                    tracking-wide"
                                >
                                    Login
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Secondary Navigation */}
            <div className="lg:container mx-auto flex justify-center">
                {/* Desktop Secondary Menu */}
                <ul className="md:flex hidden mx-auto lg:container gap-12">
                    <li className='flex-1 flex justify-end items-center gap-12'>

                        {/* Discord */}
                        <Link href="https://discord.gg/pzUfxPa87U" className="p-2 active:scale-95 bg-6ixshooter-blue text-white font-bold rounded-md
          shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-blue-hover focus:outline-none 
           tracking-wide flex items-center gap-4 text-lg">
                            Join Our Discord
                        </Link>

                        {/* Training */}
                        <div className="font-medium hover:text-6ixshooter-blue-hover hover:underline transition-all duration-300 ease-in-out flex items-center gap-2 tracking-wider">
                            <NavLink label={'Training'} link={'/training'} />
                        </div>

                    </li>
                    {/* Centered Logo */}
                    <li className="">
                        <Link href={'/'}>
                            <Image
                                alt="6ix Shooter Logo"
                                src="/6ixshooter.png"
                                className="transition duration-300 ease-in-out rounded-md transform hover:scale-110"
                                width={75}
                                height={75}
                                priority

                            />
                        </Link>
                    </li>
                    <li className="flex-1 flex gap-12 items-center">

                        {/* Drills */}
                        <div className="font-medium hover:text-6ixshooter-blue-hover hover:underline transition-all duration-300 ease-in-out flex items-center gap-2 tracking-wider">
                            <NavLink label={'Drills'} link={'/drills'} />
                        </div>

                        {/* Contact Us */}
                        <div className="font-medium hover:text-6ixshooter-blue-hover hover:underline transition-all duration-300 ease-in-out flex items-center gap-2 tracking-wider">
                            <Link href="mailto:support@6ixShooter.net" className="md:text-2xl md:tracking-normal text-lg tracking-wider">
                                Contact Us</Link>
                        </div>
                        {user && (
                            user.role === USER_ROLES.ADMIN && (
                                <Link
                                    className="text-xl p-2 md:px-3 md:py-2 bg-6ixshooter-red text-white font-bold rounded-md  h-fit
                                    active:scale-95
                                        shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-red-hover focus:outline-none 
                                        tracking-wide"
                                    href={'/course'}
                                >
                                    Course Page
                                </Link>
                            )
                        )}
                    </li>
                </ul>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <ul className="md:hidden flex flex-col items-center space-y-4 py-8 w-full">
                    
                    

                    <li className="font-medium text-white">

                        <NavLink
                            label={'Training'}
                            link={'/training'}
                            onClick={() => setIsMobileMenuOpen(false)}
                        />
                    </li>
                    <li className="font-medium text-white">
                        <NavLink
                            label={'Drills'}
                            link={'/drills'}
                            onClick={() => setIsMobileMenuOpen(false)}
                        />
                    </li>
                    <li className="font-medium text-white">

                        <Link href="mailto:support@6ixShooter.net" className="md:text-2xl md:tracking-normal text-lg tracking-wider">
                            Contact Us</Link>

                    </li>
                    
                    {user ? (
                        <>
                            <li>
                                <Link
                                    href={'/profile'}
                                    className="block active:scale-95 p-2 w-full bg-6ixshooter-blue text-white font-bold rounded-md 
                                    shadow-md shadow-gray-600 transition duration-300 ease-in-out focus:outline-none 
                                    tracking-wide"
                                    onClick={() => setIsMobileMenuOpen(false)}
                                >
                                    Profile
                                </Link>
                            </li>
                            {user && (

                                user.role === USER_ROLES.ADMIN && (
                                    <li className='flex flex-col gap-4'>
                                        <Link
                                            href={'/admin'}
                                            className="block p-2 w-full bg-6ixshooter-red text-white font-bold rounded-md 
                                            active:scale-95
                                        shadow-md shadow-gray-600 transition duration-300 ease-in-out focus:outline-none 
                                        tracking-wide"
                                            onClick={() => setIsMobileMenuOpen(false)}
                                        >
                                            Admin Page
                                        </Link>
                                        <Link
                                            className=" p-2 md:px-3 md:py-2 bg-6ixshooter-red
                                             text-white font-bold rounded-md  h-fit
                                             active:scale-95
                                        shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-red-hover focus:outline-none 
                                        tracking-wide"
                                            href={'/course'}
                                        >
                                            Course Page
                                        </Link>
                                    </li>

                                )
                            )}
                        </>
                    ) : (
                        <>

                            <li className='h-fit '>
                                <Link
                                    href={'/auth/login'}
                                    className="block p-2 w-20 text-center bg-6ixshooter-blue text-white font-bold rounded-md 
                                    shadow-md shadow-gray-600 transition duration-300 ease-in-out focus:outline-none 
                                    tracking-wide"
                                    onClick={() => setIsMobileMenuOpen(false)}
                                >
                                    Login
                                </Link>
                            </li>

                        </>
                    )}
                    <li className="font-medium text-white">
                        <Link href="https://discord.gg/pzUfxPa87U" className="p-2 active:scale-95 bg-6ixshooter-blue text-white font-bold rounded-md
          shadow-md shadow-gray-600 transition duration-300 ease-in-out hover:bg-6ixshooter-blue-hover focus:outline-none 
           tracking-wide flex items-center gap-4 ">
                            Join Our Discord
                        </Link>
                    </li>
                </ul>
            )}

        </nav>
    );
}