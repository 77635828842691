// utils/constants.js

export const USER_ROLES = {
    USER: 'USER',
    ADMIN: 'ADMIN',
    MODERATOR: 'MODERATOR',
};

export const PRODUCT_TYPES = {
    COURSE: 'COURSE',
    DRILL: 'DRILL',
    UNLOCK_ALL_DRILLS: 'UNLOCK_ALL_DRILLS',
    SUBSCRIPTION: 'SUBSCRIPTION',
    VIRTUAL_TRAINING: 'VIRTUAL_TRAINING',
};

export const SUBSCRIPTION_STATUS = {
    ACTIVE: 'ACTIVE',
    PAST_DUE: 'PAST_DUE',
    CANCELLED: 'CANCELLED',
    UNPAID: 'UNPAID',
};

export const DISCOUNT_TYPES = {
    PERCENTAGE: 'PERCENTAGE',
    FIXED_AMOUNT: 'FIXED_AMOUNT',
};

export const SESSION_STATUS = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
};

export const VARIANT_TYPES = {
    A: 'A',
    B: 'B',
};

export const CURRENCY = 'USD';
export const CURRENCY_SYMBOL = '$';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';